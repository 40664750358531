import React from "react"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { Box, Flex } from "@rebass/emotion"
import { css } from "@emotion/core"
// import { useSpring } from "react-spring"
// import { TweenLite, ScrollToPlugin } from "gsap/all"
// import { StickyContainer, Sticky } from "react-sticky"

import {
  Layout,
  Container,
  SEO,
  Text,
  Section,
  Heading,
  Hero,
  DecoratedHeading,
} from "../components"

// const gsapPlugins = [ScrollToPlugin]

export const pageQuery = graphql`
  query StepsQuery {
    prismic10Steps {
      data {
        hero_image {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        steps {
          title1
          bottom_text {
            html
          }
          top_text {
            html
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 740) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

const Step = ({ step, index, ...props }) => {
  return (
    <Section
      key={`step_${index + 1}`}
      bg={index % 2 === 0 ? "grey" : "white"}
      id={`step${index + 1}`}
      {...props}
    >
      <Container>
        <Heading>
          <Text as="span" fontSize="144px" pr="4">
            {index + 1}
          </Text>
          {step.title1}
        </Heading>
        <Text
          as="div"
          dangerouslySetInnerHTML={{ __html: step.top_text.html }}
        />

        <Flex
          flexWrap="wrap"
          pt="3"
          css={css`
            position: relative;
            ${index % 2 !== 0 && `justify-content: flex-end`};
          `}
        >
          <Box
            bg="black"
            color="white"
            width={[1, 1, 1, 1 / 2]}
            px="4"
            py="5"
            css={theme => css`
              border-radius: ${theme.borderRadius[1]};
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              z-index: 3;

              ${theme.mq[2]} {
                position: absolute;
                bottom: 0;
                ${index % 2 === 0 ? `right: 0` : `left: 0`};
              }
            `}
          >
            <Text
              as="div"
              dangerouslySetInnerHTML={{ __html: step.bottom_text.html }}
            />
          </Box>
          <Box width={[1, 1, 1, 2 / 3]} bg="#777">
            <GatsbyImage fluid={step.image.localFile.childImageSharp.fluid} />
          </Box>
        </Flex>
      </Container>
    </Section>
  )
}

export default function Steps({ data }) {
  // const [, setY] = useSpring(() => ({ y: 0 }))

  // const scrollTo = () => TweenLite.to(window, 2, { scrollTo: 400 })

  return (
    <Layout>
      <SEO title="Home" keywords={[``]} />
      <div
        css={css`
          position: relative;
        `}
      >
        <Hero
          fluid={
            data.prismic10Steps.data.hero_image.localFile.childImageSharp.fluid
          }
        >
          <Container>
            <DecoratedHeading type="cornerWhite">
              INNOVATION IN PROFESSIONAL DEVELOPMENT
            </DecoratedHeading>
          </Container>
        </Hero>
        <Container>
          <Section textAlign="center">
            <DecoratedHeading type="horizontal" h3>
              10 ways in which ELI is innovative
            </DecoratedHeading>
          </Section>
        </Container>

        <Box
          css={css`
            position: relative;
          `}
        >
          {/* <StickyContainer css={css``} a>
            
            <Sticky>
              {({
                style,
                isSticky,
                wasSticky,
                distanceFromTop,
                distanceFromBottom,
                calculatedHeight,
              }) => (
                <Box>
                  {steps.map((_, i) => (
                    <Box
                      onClick={() =>
                        TweenLite.to(window, 2, { scrollTo: `#step${i + 1}` })
                      }
                    >
                      <Text>Step {i + 1}</Text>
                    </Box>
                  ))}
                </Box>
              )}
            </Sticky>
          </StickyContainer> */}
          {/* <Box
            css={css`
              position: absolute;
              z-index: 3;
              height: 100%;
              padding: 20px;
            `}
          >
            <Box
              css={css`
                position: sticky;
                top: ${props => parseInt(props.theme.heights.nav) + 20}px;
                width: 200px;
              `}
            >
              {steps.map((_, i) => (
                <Box
                  onClick={() =>
                    TweenLite.to(window, 2, { scrollTo: `#step${i + 1}` })
                  }
                >
                  <Text>Step {i + 1}</Text>
                </Box>
              ))}
            </Box>
          </Box> */}
          <Box
            css={css`
              position: relative;
            `}
          >
            {data.prismic10Steps.data.steps.map((step, i) => (
              <Step step={step} index={i} key={i} />
            ))}
          </Box>
        </Box>
      </div>
    </Layout>
  )
}
